import React, { useEffect, useState } from "react";
import Header from "../component/Header";
import Footer from "../component/Homepage/footer";
import service from "../Service/service";
import * as $ from "jquery";
import { RotatingLines } from "react-loader-spinner";

const Events = () => {
  const [eventData, setEventData] = useState([]);

  const fetchAllEvents = () => {
    service
      .fetch_all_event_api()
      .then((res) => {
        if (res.data.code === 200) {
          setEventData(res.data.retrived_data);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchAllEvents();
  }, []);

  if (eventData?.length > 0) {
    $(".myElem").hide();
    $(".DNone").hide();
  } else {
    $(".myElem").hide();
    $(".DNone").removeClass();
  }
  return (
    <>
      {/* <Header /> */}
      <div class="rs-breadcrumbs img2">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title">Event</h1>
          <ul>
            <li>
              <a class="active" href="/">
                Home
              </a>
            </li>
            <li>Event</li>
          </ul>
        </div>
      </div>

      <div className="rs-services style2 pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="myElem">
          <RotatingLines width="100" strokeColor="#03228f" />
        </div>
        <h2 className="DNone text-center">Currently, No event available</h2>
        <div className="container">
          <div className="row">
            {eventData?.map((data) => {
              return (
                <div className="col-lg-4 col-md-6 col-sm-12 mb-25">
                  <div className="flip-box-inner">
                    <div className="flip-box-wrap">
                      <div className="front-part">
                        <div className="front-content-part">
                          <div className="front-icon-part">
                            <div className="icon-part">
                              <img src="assets/images/eventlogo.png" alt="" />
                            </div>
                          </div>
                          <div className="front-title-part">
                            <h3 className="title">
                              <a href="!#">{data.title}</a>
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="back-front justify-content-center">
                        <div className="back-front-content">
                          <div className="back-title-part">
                            <h3 className="back-title">
                              <a href="!#">{data.title}</a>
                            </h3>
                          </div>
                          <div className="back-btn-part">
                            <a
                              className="readon view-more"
                              href="/about_event"
                              onClick={() => {
                                localStorage.setItem("E_Id", data.id);
                              }}
                            >
                              View Event
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Events;
