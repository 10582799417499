import axios from "axios";

const base_url = "https://api.pcodertechnologies.com/api";

// ------------------- Brand Management API ---------------------

const add_brand = async (data) => {
  let response = axios.post(base_url + "/add_brand", data);
  return response;
};

const fetch_all_brand = async () => {
  let response = axios.get(base_url + "/fetch_all_brand");
  return response;
};

const fetch_brand_by_id = async (data) => {
  let response = axios.get(base_url + `/fetch_brand?id=${data}`);
  return response;
};

const delete_brand = async (data) => {
  let response = axios.put(base_url + "/delete_brand", data);
  return response;
};

// ------------------- Appointment Management API ---------------------

const fetch_all_appointment = async () => {
  let response = axios.get(base_url + "/fetch_all_appointment");
  return response;
};

const fetch_appointment_by_id = async (data) => {
  let response = axios.get(base_url + `/fetch_appointment?id=${data}`);
  return response;
};

const appointment = async (data) => {
  let response = axios.post(base_url + "/appointment", data);
  return response;
};

// ------------------- Project Management API ---------------------

const add_project = async (data) => {
  let response = axios.post(base_url + "/add_project", data);
  return response;
};

const fetch_all_project = async () => {
  let response = axios.get(base_url + `/fetch_all_project`);
  return response;
};
const fetch_project_by_id = async (data) => {
  let response = axios.get(base_url + `/fetch_project?id=${data}`);
  return response;
};

const delete_project = async (data) => {
  let response = axios.put(base_url + `/delete_project`, data);
  return response;
};

// ADD API -----------------------------------------

const contect_us = async (data) => {
  let response = axios.post(base_url + "/contect_us", data);
  return response;
};

const add_email = async (data) => {
  let response = axios.post(base_url + "/add_email", data);
  return response;
};

// ------------------- Blog Management API ---------------------

const fetch_all_blog = async () => {
  let response = axios.get(base_url + `/fetch_all_blog`);
  return response;
};
const fetch_blog_by_id = async (data) => {
  let response = axios.get(base_url + `/fetch_blog?id=${data}`);
  return response;
};

// event api
const fetch_all_event_api = async () => {
  let response = axios.get(base_url + `/fetch_all_event`);
  return response;
};

// event next api
const fetch_event_by_id_function = async (data) => {
  let response = axios.get(base_url + `/fetch_event_byid?id=${data}`);
  return response;
};

const fetch_all_jobs = async () => {
  let response = axios.get(base_url + `/fetch_all_jobs`);
  return response;
};

const fetch_job = async (data) => {
  let response = axios.get(base_url + `/fetch_job?job_id=${data}`);
  return response;
};

const add_application = async (data) => {
  let response = axios.post(base_url + "/add_application", data);
  return response;
};

export default {
  add_brand,
  fetch_all_brand,
  fetch_brand_by_id,
  delete_brand,
  appointment,
  fetch_all_appointment,
  fetch_appointment_by_id,
  add_project,
  fetch_all_project,
  fetch_project_by_id,
  delete_project,
  contect_us,
  add_email,
  fetch_all_blog,
  fetch_blog_by_id,
  fetch_all_event_api,
  fetch_event_by_id_function,
  fetch_all_jobs,
  fetch_job,
  add_application,
};
