import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import Footer from "../component/Homepage/footer";
import service from "../Service/service";
import { Markup } from "interweave";
import * as $ from "jquery";
import { RotatingLines } from "react-loader-spinner";
import moment from "moment";

export default function Blog() {
  const [allBlogs, setAllBlogs] = useState([]);

  const fetchAll = () => {
    service.fetch_all_blog().then((res) => {
      if (res.data.code === 200) {
        setAllBlogs(res.data.data);
      }
    });
  };

  useEffect(() => {
    fetchAll();
  }, []);

  if (allBlogs?.length > 0) {
    $(".myElem").hide();
    $(".DNone").hide();
  } else {
    $(".myElem").hide();
    $(".DNone").removeClass();
  }

  return (
    <>
      {/* <Header /> */}
      <div class="rs-breadcrumbs img2">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title">Blog</h1>
          <ul>
            <li>
              <a class="active" href="/">
                Home
              </a>
            </li>
            <li>Blog</li>
          </ul>
        </div>
      </div>

      <div className="rs-inner-blog pt-120 pb-120 md-pt-90 md-pb-90">
        <div className="myElem">
          <RotatingLines width="100" strokeColor="#03228f" />
        </div>
        <h2 className="DNone text-center">Currently, No blog available</h2>
        <div className="container">
          <div className="row">
            {allBlogs &&
              allBlogs?.map((data, index) => {
                return (
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-50">
                    <div className="blog-item" style={{ height: 400 }}>
                      <div className="blog-img">
                        <img
                          src={
                            `https://pcoder.s3.ap-south-1.amazonaws.com/` +
                            data.image
                          }
                          alt={data.title}
                        />
                      </div>
                      <div className="blog-content">
                        <h3 className="blog-title">
                          <a href="!#">
                            <Markup content={data.title} />
                          </a>
                        </h3>
                        <div className="blog-meta">
                          <ul className="btm-cate">
                            <li>
                              <div className="blog-date">
                                <i className="fa fa-calendar-check-o" />{" "}
                                {moment(data.updated_at).format("LL")}
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div
                          className="blog-button inner-blog"
                          style={{ textAlign: "end" }}
                        >
                          <a
                            className="blog-btn"
                            href={`/about_blog/${data.id}`}
                            onClick={() => {
                              localStorage.setItem("B_Id", data.id);
                            }}
                          >
                            Read in Detail
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
