import React from "react"

export default function About(){

    return (
        <div id="rs-about" className="rs-about md-pt-70">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-5">
                    <div className="sec-title mb-50">
                        <div className="sub-text style4-bg">About Us</div>
                        <h2 className="title pb-20">
                            Over 2500+ Brands and 1000+ Happy Customer
                        </h2>
                        <div className="desc">
                        Pcoder Technologies helps you to meet your business goals, We create, develop, consult and provide the best and most reliable product that will create a new face of your idea to the world
                          We are in the business to create Wealth and Apperience online and help new entrepreneurs grow without hesitation.
                          Pcoder Technologies understand modern world depends on the Internet and we are Fulfill the requirement.  
                          </div>
                    </div>
                    <div className="rs-counter style3">
                        <div className="container">
                            <div className="counter-top-area">
                                <div className="row">
                                    <div className="col-sm-6 sm-pr-0 sm-pl-0 xs-mb-30">
                                        <div className="counter-list">
                                            <div className="counter-text">
                                                <div className="count-number">
                                                    <span className="rs-count plus orange-color">150</span>
                                                </div>
                                                <h3 className="title">Happy Clients</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 sm-pr-0 sm-pl-0">
                                        <div className="counter-list">
                                            <div className="counter-text">
                                                <div className="count-number">
                                                    <span className="rs-count plus">850</span>
                                                </div>
                                                <h3 className="title">Project Delivered</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="rs-services style3 md-pt-50">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 pr-10 pt-40 sm-pt-0 sm-pr-0 sm-pl-0">
                                   <div className="services-item mb-20">
                                       <div className="services-icon">
                                           <div className="image-part">
                                               <img className="main-img" src="assets/images/services/style3/main-img/1.png" alt=""/>
                                               <img className="hover-img" src="assets/images/services/style3/hover-img/1.png" alt=""/>
                                           </div>
                                       </div>
                                       <div className="services-content">
                                           <div className="services-text">
                                               <h3 className="title">High-quality Code</h3>
                                           </div>
                                           <div className="services-desc">
                                               <p>                                                   The Best Quality product begins with the best quality Code. Our Developer believes in high-end coding which leads to creating a highly scalable product
                                               </p>
                                           </div>
                                       </div>
                                   </div>
                                   <div className="services-item cyan-bg">
                                       <div className="services-icon">
                                           <div className="image-part">
                                               <img className="main-img" src="assets/images/services/style3/main-img/2.png" alt=""/>
                                               <img className="hover-img" src="assets/images/services/style3/hover-img/2.png" alt=""/>
                                           </div>
                                       </div>
                                       <div className="services-content">
                                           <div className="services-text">
                                               <h3 className="title">Full-stack teams</h3>
                                           </div>
                                           <div className="services-desc">
                                               <p>
                                               We are the one-stop solution for every possible Software Work you ever imagine. In short, everything is under a hood.  
                                               </p>
                                           </div>
                                       </div>
                                   </div>  
                                </div>
                                <div className="col-md-6 pl-10 sm-pr-0 sm-pl-0 sm-mt-20">
                                   <div className="services-item gold-bg mb-20">
                                       <div className="services-icon">
                                           <div className="image-part">
                                               <img className="main-img" src="assets/images/services/style3/main-img/3.png" alt=""/>
                                               <img className="hover-img" src="assets/images/services/style3/hover-img/3.png" alt=""/>
                                           </div>
                                       </div>
                                       <div className="services-content">
                                           <div className="services-text">
                                               <h3 className="title">Agile Approach</h3>
                                           </div>
                                           <div className="services-desc">
                                               <p>                                                   We love the management of Agile, and Continuing it we Develop, work and manage everything on agile.  
                                               </p>
                                           </div>
                                       </div>
                                   </div>
                                   
                                   <div className="services-item blue-bg">
                                       <div className="services-icon">
                                           <div className="image-part">
                                               <img className="main-img" src="assets/images/services/style3/main-img/4.png" alt=""/>
                                               <img className="hover-img" src="assets/images/services/style3/hover-img/4.png" alt=""/>
                                           </div>
                                       </div>
                                       <div className="services-content">
                                           <div className="services-text">
                                               <h3 className="title">Security with NDA</h3>
                                           </div>
                                           <div className="services-desc">
                                               <p>
                                               We Respect your Security for your idea and are happy to lock it between us, we offer NDA and white labels According to your requirement 
                                               </p>
                                           </div>
                                       </div>
                                   </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}