import React, { useState, useEffect } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import service from "../Service/service";
import * as $ from "jquery";
import { RotatingLines } from "react-loader-spinner";

export default function BrandBanner() {
  const [brand, setBrand] = useState([]);
  const handleDragStart = (e) => e.preventDefault();

  const fetch_all = () => {
    service.fetch_all_brand().then((res) => {
      setBrand(res.data.brands);
    });
  };
  useEffect(() => {
    fetch_all();
  }, []);

  let items = brand?.map((data) => {
    return (
      <img
        src={data.logo}
        style={{ width: "150px", height: "150px" }}
        onDragStart={handleDragStart}
        alt={data.logo}
      />
    );
  });

  const responsive = {
    0: { items: 1 },
    204: { items: 2 },
    408: { items: 3 },
    612: { items: 4 },
    816: { items: 5 },
    1024: { items: 6 },
  };

  if (brand?.length > 0) {
    $(".myElem").hide();
  }

  return (
    <div class="rs-partner style2 py-5">
      <div className="myElem">
        <RotatingLines width="100" strokeColor="#03228f" />
      </div>
      <div class="container">
        <AliceCarousel
          mouseTracking
          items={items}
          disableButtonsControls={true}
          disableDotsControls={true}
          animationDuration={2000}
          autoPlay
          infinite
          responsive={responsive}
        />
      </div>
    </div>
  );
}
