// SnackbarComponent.js
import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Mysnackbar = ({ open, message, onClose,severity }) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    onClose();
  };

  return (
    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
      <MuiAlert

        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={severity?severity:"info"}
        sx={{ width: "100%" }}
        // key={{ vertical: "top", horizontal: "center" }}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default Mysnackbar;
