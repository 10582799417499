import React, { useEffect, useState } from "react";
import Header from "../component/Header";
import Footer from "../component/Homepage/footer";
import service from "../Service/service";
import { Select, Input } from "antd";
import * as $ from "jquery";
import { RotatingLines } from "react-loader-spinner";

const { Option } = Select;

const Careers = () => {
  const [careersData, setCareersData] = useState([]);
  const [filterPosition, setFilterPosition] = useState("");
  const [filterJobType, setFilterJobType] = useState("");
  const [filterLocation, setFilterLocation] = useState("");
  const [searchData, setSearchData] = useState("");
  const [active, setActive] = useState(false);

  const fetch_CareerData = () => {
    service
      .fetch_all_jobs()
      .then((res) => {
        setCareersData(res.data.brands);
      })
      .catch((e) => {});
  };
  useEffect(() => {
    fetch_CareerData();
  }, []);

  const onSearch = (value) => {};

  if (careersData?.length > 0) {
    $(".myElem").hide();
    $(".DNone").hide();
  } else {
    $(".myElem").hide();
    $(".DNone").removeClass();
  }

  return (
    <>
      {/* <Header /> */}

      <div class="rs-breadcrumbs img1">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title">Careers</h1>
          <ul>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a class="active" href="/">
                Home
              </a>
            </li>
            <li>Careers</li>
          </ul>
        </div>
      </div>

      <div className="rs-services style4 modify1 services3 gray-color pt-60 pb-60 md-pt-80 md-pb-80">
        <div className="container">
          <div className="myElem">
            <RotatingLines width="100" strokeColor="#03228f" />
          </div>
          <h2 className="DNone text-center">Currently, No job available</h2>
          {careersData?.length > 0 ? (
            <div className="mt-3 mb-3 CareerFilter">
              <div className="CFilter2 ml-2">
                <Input
                  placeholder="Search Job"
                  style={{ width: "auto" }}
                  size="large"
                  onChange={(e) => {
                    setSearchData(e.target.value);
                  }}
                />
              </div>
              <div className="CFilter2 ml-2">
                <Select
                  showSearch
                  size="large"
                  placeholder="Filter-by Position"
                  optionFilterProp="position"
                  onChange={(value) => {
                    setActive(true);
                    setFilterPosition(value);
                  }}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  <Option value="Front-end Developer">
                    Front-end Developer
                  </Option>
                  <Option value="Back-end Developer">Back-end Developer</Option>
                  <Option value="Full-stack Developer">
                    Full-stack Developer
                  </Option>
                </Select>
              </div>
              <div className="CFilter2 ml-2">
                <Select
                  showSearch
                  size="large"
                  placeholder="Filter-by Job-Type"
                  optionFilterProp="job type"
                  onChange={(value) => {
                    setActive(true);
                    setFilterJobType(value);
                  }}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  <Option value="Full-time">Full-time</Option>
                  <Option value="Internship">Internship</Option>
                  <Option value="Part-time">Part-time</Option>
                </Select>
              </div>
              <div className="CFilter2 ml-2">
                <Select
                  showSearch
                  size="large"
                  placeholder="Filter-by Location"
                  optionFilterProp="position"
                  onChange={(value) => {
                    setActive(true);
                    setFilterLocation(value);
                  }}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  <Option value="Remote">Remote</Option>
                  <Option value="Bhilai, Chhattisgarh (Remote)">
                    Bhilai, Chhattisgarh (Remote)
                  </Option>
                  <Option value="Hybrid">Hybrid</Option>
                </Select>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="row">
            {active === true
              ? careersData
                  ?.filter(
                    (item) =>
                      item.position === filterPosition ||
                      item.type === filterJobType ||
                      item.location === filterLocation
                  )
                  ?.map((data) => {
                    return (
                      <div className="col-lg-4 col-md-6 mb-20">
                        <div className="services-item">
                          <div className="services-content">
                            <h2 className="title">
                              <a href="!#">{data.position}</a>
                            </h2>
                            <div class="sec-title2">
                              <span class="sub-text style-bg">{data.type}</span>
                            </div>
                            <p className="desc">{data.location}</p>
                            <div className="blog-button inner-blog">
                              <a
                                className="blog-btn"
                                href="/career_specs"
                                onClick={() => {
                                  localStorage.setItem("J_Id", data.job_id);
                                }}
                              >
                                View & Apply
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
              : careersData
                  ?.filter((val) => {
                    if (searchData === "") {
                      return val;
                    } else if (
                      val.position
                        .toLowerCase()
                        .includes(searchData.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      val.location
                        .toLowerCase()
                        .includes(searchData.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      val.type.toLowerCase().includes(searchData.toLowerCase())
                    ) {
                      return val;
                    }
                    return val;
                  })
                  ?.map((data) => {
                    return (
                      <div className="col-lg-4 col-md-6 mb-20">
                        <div className="services-item">
                          <div className="services-content">
                            <h2 className="title">
                              <a href="!#">{data.position}</a>
                            </h2>
                            <div class="sec-title2">
                              <span class="sub-text style-bg">{data.type}</span>
                            </div>
                            <p className="desc">{data.location}</p>
                            <div className="blog-button inner-blog">
                              <a
                                className="blog-btn"
                                href="/career_specs"
                                onClick={() => {
                                  localStorage.setItem("J_Id", data.job_id);
                                }}
                              >
                                View & Apply
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Careers;
