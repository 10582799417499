import React from "react";
import Contact from "./contact";
import { useHistory } from "react-router-dom";

export default function Extra() {
  return (
    <>
      <div className="rs-about style2 modify1 pt-120 pb-120 md-pt-70 md-pb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 hidden-md">
              <div className="images">
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src="assets/images/about/about-us/aboutus-section.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 pl-60 md-pl-15">
              <div className="sec-title mb-30">
                <div className="sub-text style4-bg">About Us</div>
                <h2 className="title pb-20">
                  We Are Increasing Business Success With Technology
                </h2>
                <div className="desc"></div>
              </div>
              <div className="rs-skillbar style1 home4">
                <div className="cl-skill-bar">
                  <span className="skillbar-title">Software Development</span>
                  <div className="skillbar" data-percent="92">
                    <p className="skillbar-bar" style={{ width: "92%" }}></p>
                    <span className="skill-bar-percent">92%</span>
                  </div>
                  <span className="skillbar-title">Mobile App Development</span>
                  <div className="skillbar" data-percent="85">
                    <p
                      className="skillbar-bar paste-bg"
                      style={{ width: "85%" }}
                    ></p>
                    <span className="skill-bar-percent">85%</span>
                  </div>
                  <span className="skillbar-title">Website Development</span>
                  <div className="skillbar" data-percent="95">
                    <p
                      className="skillbar-bar blue-bg"
                      style={{ width: "95%" }}
                    ></p>
                    <span className="skill-bar-percent">95%</span>
                  </div>
                  <span className="skillbar-title">IT Support</span>
                  <div className="skillbar" data-percent="78">
                    <p
                      className="skillbar-bar pink-bg"
                      style={{ width: "78%" }}
                    ></p>
                    <span className="skill-bar-percent">78%</span>
                  </div>
                  <div className="btn-part mt-45">
                    <a className="readon started text-white" href="/ourwork">
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* service  */}
      <div class="rs-services style3 rs-rain-animate gray-color pt-120 pb-120 md-pt-70 md-pb-80">
        <div class="container">
          <div class="row md-mb-60">
            <div class="col-lg-6 mb-60 md-mb-20">
              <div class="sec-title2 md-center">
                <span class="sub-text">Services</span>
                <h2 class="title testi-title">
                  What Solutions We Provide to Our Valued Customers
                </h2>
              </div>
            </div>
            <div class="col-lg-6 mb-60 md-mb-0">
              <div class="btn-part text-right mt-60 md-mt-0 md-center">
                <a class="readon started text-white" href="/service">
                  Get Started
                </a>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 mb-20">
              <div class="services-item">
                <div class="services-icon">
                  <div class="image-part">
                    <img
                      class="main-img"
                      src="assets/images/services/style2/main-img/1.png"
                      alt=""
                    />
                    <img
                      class="hover-img"
                      src="assets/images/services/style2/hover-img/1.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title">
                      <a>Software Development</a>
                    </h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Software Development with the Technology Like{" "}
                      <b>
                        <i class="fas fa-atom-alt"></i>Electron,{" "}
                        <i class="fab fa-cuttlefish"></i>C++,{" "}
                        <i class="fab fa-java"></i>Java,{" "}
                        <i class="fab fa-python"></i>Python
                      </b>
                    </p>
                  </div>
                  <div class="serial-number">01</div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-20">
              <div class="services-item pink-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img
                      class="main-img"
                      src="assets/images/services/style2/main-img/2.png"
                      alt=""
                    />
                    <img
                      class="hover-img"
                      src="assets/images/services/style2/hover-img/2.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title">
                      <a>Web Development</a>
                    </h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Web Development with the Technology Like{" "}
                      <b>
                        {" "}
                        <i class="fab fa-angular"></i>Angular,&nbsp;{" "}
                        <i class="fab fa-react"></i>React
                      </b>
                    </p>
                  </div>
                  <div class="serial-number">02</div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-20">
              <div class="services-item aqua-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img
                      class="main-img"
                      src="assets/images/services/style2/main-img/3.png"
                      alt=""
                    />
                    <img
                      class="hover-img"
                      src="assets/images/services/style2/hover-img/3.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title">
                      <a>Digital Marketing</a>
                    </h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Digital Marketing Solution with{" "}
                      <b>SEO, SSO, Campaign Management, SMO</b>
                    </p>
                  </div>
                  <div class="serial-number">03</div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 md-mb-20">
              <div class="services-item paste-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img
                      class="main-img"
                      src="assets/images/services/style2/main-img/4.png"
                      alt=""
                    />
                    <img
                      class="hover-img"
                      src="assets/images/services/style2/hover-img/4.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title">
                      <a>Clould & DevOps</a>
                    </h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      we work to develope high end product with the help of{" "}
                      <b>
                        <i class="fab fa-aws"></i>AWS,{" "}
                        <i class="fab fa-google-plus"></i>GSP,{" "}
                        <i class="fas fa-code-merge"></i>Firebase,{" "}
                        <i class="fab fa-digital-ocean"></i>Digital Ocean
                      </b>
                    </p>
                  </div>
                  <div class="serial-number">04</div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 sm-mb-20">
              <div class="services-item blue-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img
                      class="main-img"
                      src="assets/images/services/style2/main-img/5.png"
                      alt=""
                    />
                    <img
                      class="hover-img"
                      src="assets/images/services/style2/hover-img/5.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title">
                      <a>Product & Design</a>
                    </h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      Design a Product is a biggest step towards your dream and
                      we fulfill by amazing tools like.{" "}
                      <b>
                        <i class="fab fa-figma"></i>Figma,{" "}
                        <i class="fab fa-xing-square"></i>XD,{" "}
                        <i class="fab fa-sketch"></i>Sketch,{" "}
                      </b>{" "}
                    </p>
                  </div>
                  <div class="serial-number">05</div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="services-item green-bg">
                <div class="services-icon">
                  <div class="image-part">
                    <img
                      class="main-img"
                      src="assets/images/services/style2/main-img/6.png"
                      alt=""
                    />
                    <img
                      class="hover-img"
                      src="assets/images/services/style2/hover-img/6.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-text">
                    <h3 class="title">
                      <a>Maintenance and support</a>
                    </h3>
                  </div>
                  <div class="services-desc">
                    <p>
                      we provide Support to all product either it is develop by
                      us or not. Expert developer team will provide{" "}
                      <b>24X7 Support</b>.
                    </p>
                  </div>
                  <div class="serial-number">06</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="line-inner">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
      </div>

      {/* extra abut */}
      <div class="rs-why-choose pt-120 pb-120 md-pt-70 md-pb-75">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 hidden-md">
              <div class="image-part">
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src="assets/images/about/about-us/aboutus-section-chooseus.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="animation style2">
                <div class="top-shape">
                  <img
                    class="dance"
                    src="assets/images/choose/dotted-2.png"
                    alt="images"
                  />
                </div>
                <div class="bottom-shape">
                  <img
                    class="dance2"
                    src="assets/images/choose/dotted-1.png"
                    alt="images"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6 pl-60 md-pl-15">
              <div class="sec-title mb-40">
                <div class="sub-text style4-bg left">Why Choose Us</div>
                <h2 class="title pb-20">
                  We Create Result-Oriented Dynamic Applications
                </h2>
                <div class="desc">
                  Everything begins with the reason and we have a ton of reasons
                  to choose us for your valuable project development
                </div>
              </div>
              <div class="services-wrap mb-25">
                <div class="services-icon">
                  <img src="assets/images/choose/icons/1.png" alt="" />
                </div>
                <div class="services-text">
                  <h3 class="title">
                    <a>Fast Growing Process</a>
                  </h3>
                  <p class="services-txt">
                    {" "}
                    Start your project in just 1 Call.
                  </p>
                </div>
              </div>
              <div class="services-wrap mb-25">
                <div class="services-icon">
                  <img src="assets/images/choose/icons/2.png" alt="" />
                </div>
                <div class="services-text">
                  <h3 class="title">
                    <a>Clean code</a>
                  </h3>
                  <p class="services-txt">
                    {" "}
                    Get a high end and clean code which never reduce the
                    usablity of the product.
                  </p>
                </div>
              </div>
              <div class="services-wrap">
                <div class="services-icon">
                  <img
                    style={{ width: "80px" }}
                    src="assets/images/choose/icons/3.png"
                    alt=""
                  />
                </div>
                <div class="services-text">
                  <h3 class="title">
                    <a>Well Documentation</a>
                  </h3>
                  <p class="services-txt">
                    {" "}
                    You will get Everything Documented just after development
                    started. we hand over the document after product delivery.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </>
  );
}
