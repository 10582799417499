import React from "react"
import BrandBanner from "../component/Brand_banner"
import Header from "../component/Header"
import Hero from "../component/Hero"
import About from "../component/Homepage/AboutSection"
import Extra from "../component/Homepage/extra"
import Footer from "../component/Homepage/footer"
import Partner from "../component/Homepage/Partner"

export default function Home(){
    return (
        <>
            <div className="offwrap"></div>
            <div id="loader" className="loader" style={{display: "none"}}>
            <div className="loader-container"></div>
              </div>
            {/* <Header /> */}
             <Hero />
             {/* <BrandBanner /> */}
             <About />
             <Partner />
             <Extra />
             <Footer /> 
        </>
        
    )
}