import React, { useState } from "react";
import Header from "../component/Header";

export default function Service() {
  const [selected, setSelected] = useState("Web_Development");

  return (
    <>
      {/* <Header /> */}
      {selected === "Web_Development" ? (
        <div class="rs-breadcrumbs img1">
          <div class="breadcrumbs-inner text-center">
            <h1 class="page-title">Web Development</h1>
            <ul>
              <li title="Braintech - IT Solutions and Technology Startup HTML Template">
                <a class="active" href="/">
                  Home
                </a>
              </li>
              <li title="Go To Services">
                <a class="active" href="/services">
                  Services
                </a>
              </li>
              <li>Web Development</li>
            </ul>
          </div>
        </div>
      ) : selected === "Software_Development" ? (
        <div class="rs-breadcrumbs img1">
          <div class="breadcrumbs-inner text-center">
            <h1 class="page-title">Software Development</h1>
            <ul>
              <li title="Braintech - IT Solutions and Technology Startup HTML Template">
                <a class="active" href="/">
                  Home
                </a>
              </li>
              <li title="Go To Services">
                <a class="active" href="/services">
                  Services
                </a>
              </li>
              <li>Software Development</li>
            </ul>
          </div>
        </div>
      ) : selected === "Mobile_Application_Development" ? (
        <div class="rs-breadcrumbs img2">
          <div class="breadcrumbs-inner text-center">
            <h1 class="page-title">Mobile Application Development</h1>
            <ul>
              <li title="Braintech - IT Solutions and Technology Startup HTML Template">
                <a class="active" href="/">
                  Home
                </a>
              </li>
              <li title="Go To Services">
                <a class="active" href="/services">
                  Services
                </a>
              </li>
              <li>Mobile Application Development</li>
            </ul>
          </div>
        </div>
      ) : selected === "Cloud_and_DevOps" ? (
        <div class="rs-breadcrumbs img4">
          <div class="breadcrumbs-inner text-center">
            <h1 class="page-title">Cloud and DevOps</h1>
            <ul>
              <li title="Braintech - IT Solutions and Technology Startup HTML Template">
                <a class="active" href="/">
                  Home
                </a>
              </li>
              <li title="Go To Services">
                <a class="active" href="/services">
                  Services
                </a>
              </li>
              <li>Cloud and DevOps</li>
            </ul>
          </div>
        </div>
      ) : selected === "Project_Design" ? (
        <div class="rs-breadcrumbs img2">
          <div class="breadcrumbs-inner text-center">
            <h1 class="page-title">Project Design</h1>
            <ul>
              <li title="Braintech - IT Solutions and Technology Startup HTML Template">
                <a class="active" href="/">
                  Home
                </a>
              </li>
              <li title="Go To Services">
                <a class="active" href="/services">
                  Services
                </a>
              </li>
              <li>Project Design</li>
            </ul>
          </div>
        </div>
      ) : null}
      <div class="rs-services-single pt-120 pb-120 md-pt-80 md-pb-80">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 md-mb-50">
              <div class="services-img">
                {selected === "Web_Development" ? (
                  <img
                    src="https://pcodertechnologies.com/pcoder-assets/Webiste+Development+(2).png"
                    alt=""
                  />
                ) : selected === "Software_Development" ? (
                  <img
                    src="https://pcodertechnologies.com/pcoder-assets/Webiste+Development+(1).png"
                    alt=""
                  />
                ) : selected === "Mobile_Application_Development" ? (
                  <img
                    src="https://pcodertechnologies.com/pcoder-assets/Webiste+Development+(3).png"
                    alt=""
                  />
                ) : selected === "Cloud_and_DevOps" ? (
                  <img
                    src="https://pcodertechnologies.com/pcoder-assets/Webiste+Development.png"
                    alt=""
                  />
                ) : selected === "Project_Design" ? (
                  <img
                    src="https://pcodertechnologies.com/pcoder-assets/Webiste+Development.png"
                    alt=""
                  />
                ) : selected === "Data_Center" ? (
                  <img src="assets/images/services/single/2.jpg" alt="" />
                ) : null}
              </div>
              <h2 class="mt-34" style={{ fontWeight: 700 }}>
                <i>What We have for You</i>
              </h2>

              <ul class="listing-style">
                <li>
                  <i class="fa fa-check-circle"></i>
                  <span style={{ fontSize: "20px" }}>
                    <strong>Best in class Service</strong>
                  </span>
                </li>
                <li>
                  <i class="fa fa-check-circle"></i>
                  <span style={{ fontSize: "20px" }}>
                    <strong>Excellent 24x7 Support</strong>
                  </span>
                </li>
                <li>
                  <i class="fa fa-check-circle"></i>
                  <span style={{ fontSize: "20px" }}>
                    <strong>High reliable software</strong>
                  </span>
                </li>
                <li>
                  <i class="fa fa-check-circle"></i>
                  <span style={{ fontSize: "20px" }}>
                    <strong>Award winning UI/UX Design</strong>
                  </span>
                </li>
              </ul>
              <h3 class="mt-34">Why Work With Us</h3>
              <div class="rs-skillbar style1 modify1">
                <div class="cl-skill-bar">
                  <div class="row">
                    <div class="col-lg-6">
                      <span class="skillbar-title">Software Development</span>
                      <div class="skillbar" data-percent="92">
                        <p class="skillbar-bar" style={{ width: "92%" }}></p>
                        <span class="skill-bar-percent"></span>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <span class="skillbar-title">Web Development</span>
                      <div class="skillbar" data-percent="90">
                        <p class="skillbar-bar" style={{ width: "90%" }}></p>
                        <span class="skill-bar-percent"></span>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <span class="skillbar-title">Mobile App Development</span>
                      <div class="skillbar" data-percent="98">
                        <p class="skillbar-bar" style={{ width: "98%" }}></p>
                        <span class="skill-bar-percent"></span>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <span class="skillbar-title">Cloud Maintenance</span>
                      <div class="skillbar" data-percent="92">
                        <p class="skillbar-bar" style={{ width: "92%" }}></p>
                        <span class="skill-bar-percent"></span>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <span class="skillbar-title">Clean Code</span>
                      <div class="skillbar" data-percent="92">
                        <p class="skillbar-bar" style={{ width: "90%" }}></p>
                        <span class="skill-bar-percent"></span>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <span class="skillbar-title">24x7 Support</span>
                      <div class="skillbar" data-percent="92">
                        <p class="skillbar-bar" style={{ width: "92%" }}></p>
                        <span class="skill-bar-percent"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h3 class="mt-30">24/7 Smart Support</h3>
              <p class="mt-20 mb-50">
                Your Software required special attention all the time and we are
                ready 24x7 for making your webiste or mobile app up and running.
                our team moniter the server. also out server alert system will
                provide notification when load increase. so support engineer can
                fix the issue before it raised
              </p>
              {/* <div class="row mb-80">
                <div class="col-lg-6 col-md-12 md-mb-30">
                  <img
                    class="bdru-4"
                    src="assets/images/services/single/2.jpg"
                    alt=""
                  />
                </div>
                <div class="col-lg-6 col-md-12">
                  <img
                    class="bdru-4"
                    src="assets/images/services/single/3.jpg"
                    alt=""
                  />
                </div>
              </div> */}
              {/* <div class="rs-testimonial style5">
                <div class="testi-item">
                  <div class="testi-img">
                    <img
                      src="assets/images/testimonial/main-home/quote-white2.png"
                      alt=""
                    />
                  </div>
                  <p>
                    At vero eos et accusamus et iusto odio dignissimos ducimus
                    qui blanditiis praesentium voluptatum deleniti atque
                    corrupti quos dolores et quas molestias data.
                  </p>
                  <div class="testi-content">
                    <div class="testi-img">
                      <img
                        src="assets/images/testimonial/main-home/5.jpg"
                        alt=""
                      />
                    </div>
                    <div class="author-part">
                      <div class="name">Brick John</div>
                      <span class="designation">CEO, Brick Consulting</span>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div class="col-lg-4 pl-32 md-pl-15">
              <ul class="services-list">
                <li>
                  <a
                    href="javascript:void(0);"
                    class={
                      selected === "Software_Development" ? "active" : null
                    }
                    onClick={() => setSelected("Software_Development")}
                  >
                    Software Development
                  </a>
                </li>
                <li>
                  <a
                    class={selected === "Web_Development" ? "active" : null}
                    onClick={() => setSelected("Web_Development")}
                    href="javascript:void(0);"
                  >
                    Web Development
                  </a>
                </li>
                <li>
                  <a
                    href="javascript:void(0);"
                    class={
                      selected === "Mobile_Application_Development"
                        ? "active"
                        : null
                    }
                    onClick={() =>
                      setSelected("Mobile_Application_Development")
                    }
                  >
                    Mobile Application Development
                  </a>
                </li>
                <li>
                  <a
                    href="javascript:void(0);"
                    class={selected === "Cloud_and_DevOps" ? "active" : null}
                    onClick={() => setSelected("Cloud_and_DevOps")}
                  >
                    Cloud and DevOps
                  </a>
                </li>
                <li>
                  <a
                    href="javascript:void(0);"
                    class={selected === "Project_Design" ? "active" : null}
                    onClick={() => setSelected("Project_Design")}
                  >
                    Project Design
                  </a>
                </li>
              </ul>
              <div class="services-add mb-50 mt-50">
                <div class="address-item mb-35">
                  <div class="address-icon">
                    <i class="fa fa-phone"></i>
                  </div>
                </div>
                <h2 class="title">
                  Have any Questions? <br /> Call us Today!
                </h2>
                <div class="contact">
                  <a href="tel:9691465201">(+91) 9691-465201</a>
                </div>
              </div>
              <div class="brochures">
                <h3>Brochures</h3>
                <p>
                  Want to know about company quality and project please download
                  our brochures here.
                </p>
                <div class="pdf-btn">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    class="readon learn-more pdf text-white"
                    href="https://pcodertechnologies.com/pcoder-assets/P-Coder-Technologies-Company-Profile.pdf"
                  >
                    Download Now<i class="fa fa-file-pdf-o"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rs-cta style1 bg7 pt-80 pb-80">
        <div class="container">
          <div class="cta-wrap">
            <div class="row align-items-center">
              <div class="col-lg-9 col-md-12 md-mb-30">
                <span>Plan to Start a Project</span>
                <div class="title-wrap">
                  <h2 class="epx-title">Our Experts Ready to Help You</h2>
                </div>
              </div>
              <div class="col-lg-3 text-right col-md-12">
                <div class="button-wrap">
                  <a class="readon learn-more text-white" href="/contact">
                    Get In Touch
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
