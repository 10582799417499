import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics'
import {getFirestore} from '@firebase/firestore'

// const config = {
//     apiKey: "AIzaSyBa45-oT2NXaixiKDwSz3dvgOqcQ0QP1nE",
//     authDomain: "p-coders-technologies.firebaseapp.com",
//     projectId: "p-coders-technologies",
//     storageBucket: "p-coders-technologies.appspot.com",
//     messagingSenderId: "219309562626",
//     appId: "1:219309562626:web:543687b74832d89fad401b",
//     measurementId: "G-F73DH13XKX"
// };

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

export const config = {
    apiKey: "AIzaSyCxX0TlTgOf8HebmXElxx5Y9T4Qo-OQQS8",
    authDomain: "pcoderwebsite-ce444.firebaseapp.com",
    databaseURL: "https://pcoderwebsite-ce444-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "pcoderwebsite-ce444",
    storageBucket: "pcoderwebsite-ce444.appspot.com",
    messagingSenderId: "651368778550",
    appId: "1:651368778550:web:a58d9ea2bd6757626f0197",
    measurementId: "G-F73DH13XKX"
  }

  export const app = initializeApp(config);
  export const analytics = getAnalytics(app);

  export const db = getFirestore(app)


export default {db,analytics,config};