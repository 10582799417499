import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import Home from "./pages/Home";
import AboutUs from "./pages/About";
import Service from "./pages/Service";
import Potfolio from "./pages/Potfolio";
import ContactUS from "./pages/contact";
import Blog from "./pages/Blog";
import AboutBlogs from "./pages/AboutBlogs";
import AboutProjects from "./pages/AboutProjects";
import Events from "./pages/Events";
import AboutEvent from "./pages/AboutEvent";
import Careers from "./pages/Careers";
import CareerSpecs from "./pages/CareerSpecs";
import Header from "./component/Header";
// import PageNotFound from "./pages/PageNotFound";

function App() {
  return (
    <BrowserRouter>
        <Header/>

      {
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<AboutUs />}></Route>
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/about_blog/:id" element={<AboutBlogs />} />
          <Route exact path="/Service" element={<Service />} />
          <Route exact path="/ourwork" element={<Potfolio />} />
          <Route exact path="/contact" element={<ContactUS />} />
          <Route exact path="/about_project" element={<AboutProjects />} />
          <Route exact path="/events" element={<Events />} />
          <Route exact path="/AboutEvent" element={<about_event />} />
          <Route exact path="/careers" element={<Careers />} />
          <Route exact path="/career_specs" element={<CareerSpecs />} />

        </Routes>
      }
    </BrowserRouter>
  );
}

export default App;
