import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import Footer from "../component/Homepage/footer";
import service from "../Service/service";
import $ from "jquery";
import countryCodesList from "country-codes-list";
import Mysnackbar from "../component/Mysnackbar";
// import OrderHeadSVG from "../SVG/OrderHeadSVG";
// import DiscoverySVG from "../SVG/DiscoverySVG";

export default function AboutUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_no, setPhone_no] = useState("");
  const [country_code, setCountry_code] = useState("");
  const [website, setWebsite] = useState("");
  const [your_message, setYour_message] = useState("");
  const [formErrors, setFormErrors] = useState([]);

  const [error_msg, seterror_msg] = useState("");
  const [severity, setSeverity] = useState("");

  const [isSnackbarOpen, setSnackbarOpen] = useState(false);

  const openSnackbar = () => {
    setSnackbarOpen(true);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleValidation = () => {
    let formErrors = {};
    let formIsValid = true;
    if (!name) {
      formIsValid = false;
      formErrors["name_error"] = "Name is required.";
    }
    if (!email && !phone_no) {
      if (!email) {
        formIsValid = false;
        formErrors["email_error"] = "Email is required.";
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        formIsValid = false;
        formErrors["email_error"] = "Invalid Email Address.";
      }
      if (!phone_no) {
        formIsValid = false;
        formErrors["phone_no_error"] = "Phone No. is required.";
      }
    }
    if (!your_message) {
      formIsValid = false;
      formErrors["your_message_error"] = "Your Message is required.";
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  const Req_Consultation = () => {
    let data = {
      name: name,
      email: email,
      mobile: country_code + phone_no,
      website: website,
      message: your_message,
    };
    service
      .contect_us(data)
      .then((res) => {
        if (res.data.code === 200) {
          // alert("Sent Successfully");
          seterror_msg("Sent Sucessfully");
          setSeverity("success");
          openSnackbar();
          // window.location.reload();
        } else {
          // alert("something went wrong");
          seterror_msg("Something went wrong.");
          setSeverity("error");
          openSnackbar();
        }
      })
      .catch((e) => {
        // alert(e);
        seterror_msg("Something went wrong.");
        setSeverity("error");
        openSnackbar();
      });
  };

  const autoPlayYouTubeModal = () => {
    var trigger = $("body").find('[data-toggle="modal"]');
    trigger.on("click", function () {
      var theModal = $(this).data("target"),
        videoSRC = $(this).attr("data-theVideo"),
        videoSRCauto = videoSRC + "?autoplay=1";
      $(theModal + " iframe").attr("src", videoSRCauto);
      $(theModal + " button.close").on("click", function () {
        $(theModal + " iframe").attr("src", videoSRC);
      });
    });
  };

  useEffect(() => {
    autoPlayYouTubeModal();
  });

  const stopVideoPlay = async () => {
    $(function () {
      $(".youtube-video")[0].contentWindow.postMessage(
        `{"event":"command","func":"stopVideo","args":""}`,
        "*"
      );
    });
  };
  //

  const myCountryArray = countryCodesList.all();

  return (
    <>
      {/* <Header /> */}
      <div class="rs-breadcrumbs img1">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title">About</h1>
          <ul>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a class="active" href="/">
                Home
              </a>
            </li>
            <li>About</li>
          </ul>
        </div>
      </div>
      <div class="rs-about gray-color pt-120 pb-120 md-pt-80 md-pb-80">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 md-mb-30">
              <div class="rs-animation-shape">
                <div class="images">
                  <img
                    class=""
                    src="assets/images/about/about-us/about-us-desc.png"
                    alt=""
                  />
                </div>
                {/* <div class="middle-image2">
                  <img
                    class="dance3"
                    src="assets/images/about/about-us/about-us-desc.png"
                    alt=""
                  />
                </div> */}
              </div>
            </div>
            <div class="col-lg-6 pl-60 md-pl-15">
              <div class="contact-wrap">
                <div class="sec-title mb-30">
                  <div class="sub-text style-bg">About Us</div>
                  <h2 class="title pb-38">
                    We Are Increasing Business Success With Technology
                  </h2>
                  <div class="desc pb-35">
                    Pcoder Technologies helps you to meet your business goals,
                    We create, develop, consult and provide the best and
                    reliable product that will create a new face of your idea to
                    the world We are in the business to create Wealth and
                    Apperience online and help new entrepreneurs grow without
                    hesitation.
                  </div>
                  <p class="margin-0 pb-15">
                    Pcoder Technologies understand modern world depends on the
                    Internet and we are Fulfill the requirement.
                  </p>
                </div>
                <div class="btn-part">
                  <a class="readon learn-more text-white" href="/ourwork">
                    Know-More
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="shape-image">
            <img
              class="top dance"
              src="assets/images/about/dotted-3.png"
              alt=""
            />
          </div>
        </div>
      </div>

      <div class="rs-process style2 pt-120 pb-120 md-pt-80 md-pb-73">
        <div class="container">
          <div class="sec-title2 text-center mb-45">
            <span class="sub-text style-bg">Process</span>
            <h2 class="title title2">Our Working Process</h2>
          </div>
          <div class="row">
            <div class="col-lg-3 col-sm-6 md-mb-50">
              <div class="addon-process">
                <div class="process-wrap">
                  <div class="process-img">
                    <div className="">
                      <img
                        src="assets/images/about/about-us/discovery.png"
                        alt=""
                        className=""
                      />
                    </div>
                  </div>
                  <div class="process-text">
                    <h3 class="title">Discovery</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 md-mb-50">
              <div class="addon-process">
                <div class="process-wrap">
                  <div class="process-img">
                    <div className="">
                      <img
                        src="assets/images/about/about-us/execute.png"
                        alt=""
                        className=""
                      />
                    </div>
                  </div>
                  <div class="process-text">
                    <h3 class="title"> Planning</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="addon-process">
                <div class="process-wrap">
                  <div class="process-img">
                    <div className="">
                      <img
                        src="assets/images/about/about-us/deliver.png"
                        alt=""
                        className=""
                      />
                    </div>
                  </div>
                  <div class="process-text">
                    <h3 class="title">Execute</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="addon-process">
                <div class="process-wrap">
                  <div class="process-img">
                    <div className="">
                      <img
                        src="assets/images/about/about-us/discovery.png"
                        alt=""
                        className=""
                      />
                    </div>
                  </div>
                  <div class="process-text">
                    <h3 class="title">Deliver</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="rs-contact-wrap bg5 pt-120 pb-390 md-pt-80">
        <div class="container">
          <div class="sec-title2 text-center mb-30">
            <span class="sub-text style-bg white-color">Contact</span>
            <h2 class="title white-color">Request A Free Consultation</h2>
          </div>
        </div>
      </div>
      <div class="rs-video-wrap style2 inner">
        <div class="container">
          <div class="row margin-0 gray-color">
            <div class="col-lg-6 padding-0">
              <div className="w-100 d-flex justify-content-center align-items-center">
                <img
                  src="assets/images/about/about-us/about-form.png"
                  alt=""
                  className=""
                />
              </div>
            </div>
            <div class="col-lg-6 padding-0">
              <div class="rs-requset">
                <div id="form-messages"></div>
                <form
                  id="contact-form"
                  //  method="post" action="https://rstheme.com/products/html/braintech/mailer.php"
                >
                  <fieldset>
                    <div class="row">
                      <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                        <input
                          class="from-control"
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Name"
                          required=""
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                        {formErrors.name_error && (
                          <div className="offset-0">
                            <p
                              style={{
                                color: "red",
                                fontSize: ".8rem",
                                marginTop: "0px",
                              }}
                            >
                              {formErrors.name_error}
                            </p>
                          </div>
                        )}
                      </div>
                      <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                        <input
                          class="from-control"
                          type="text"
                          id="email"
                          name="email"
                          placeholder="E-Mail"
                          required=""
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                        {formErrors.email_error && (
                          <div className="offset-0">
                            <p
                              style={{
                                color: "red",
                                fontSize: ".8rem",
                                marginTop: "0px",
                              }}
                            >
                              {formErrors.email_error}
                            </p>
                          </div>
                        )}
                      </div>
                      <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                        <div class="input-group" style={{ flexWrap: "nowrap" }}>
                          <select
                            style={{ width: "60%", padding: 0, margin: 0 }}
                            class="form-select from-control"
                            onChange={(e) => setCountry_code(e.target.value)}
                          >
                            {myCountryArray?.map((data, i) => {
                              return (
                                <option
                                  key={i}
                                  selected={
                                    parseInt(data.countryCallingCode) === 91
                                      ? true
                                      : false
                                  }
                                  value={`+${data.countryCallingCode}`}
                                >{`+${data.countryCallingCode}`}</option>
                              );
                            })}
                          </select>
                          <input
                            class="from-control"
                            type="tel"
                            id="phone"
                            name="phone"
                            placeholder="Phone Number"
                            required=""
                            onChange={(e) => {
                              setPhone_no(e.target.value);
                            }}
                          />
                        </div>
                        {formErrors.phone_no_error && (
                          <div className="offset-0">
                            <p
                              style={{
                                color: "red",
                                fontSize: ".8rem",
                                marginTop: "0px",
                              }}
                            >
                              {formErrors.phone_no_error}
                            </p>
                          </div>
                        )}
                      </div>
                      <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                        <input
                          class="from-control"
                          type="text"
                          id="Website"
                          name="subject"
                          placeholder="Your Website"
                          required=""
                          onChange={(e) => {
                            setWebsite(e.target.value);
                          }}
                        />
                      </div>
                      <div class="col-lg-12 mb-45">
                        <textarea
                          class="from-control"
                          id="message"
                          name="message"
                          placeholder="Your message Here"
                          required=""
                          onChange={(e) => {
                            setYour_message(e.target.value);
                          }}
                        ></textarea>
                        {formErrors.your_message_error && (
                          <div className="offset-0">
                            <p
                              style={{
                                color: "red",
                                fontSize: ".8rem",
                                marginTop: "0px",
                              }}
                            >
                              {formErrors.your_message_error}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div class="btn-part">
                      <div class="form-group mb-0">
                        <input
                          class="readon learn-more submit text-center"
                          value="Submit Now"
                          type="button"
                          onClick={() => {
                            if (handleValidation()) {
                              Req_Consultation();
                            } else {
                              seterror_msg("Please fill all required fields.");
                              setSeverity("info");
                              openSnackbar();
                              // alert("Please fill all required fields.");
                            }
                          }}
                        />
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Play Button Video Modal */}

      <div
        id="videoModal"
        class="modal fade bd-example-modal-lg"
        tabindex="-1"
        role="dialog"
        aria-labelledby="videoModal"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={() => stopVideoPlay()}
              >
                ×
              </button>
            </div>
            <div class="modal-body center">
              <iframe
                className="youtube-video"
                style={{ width: "100%", height: "400px" }}
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen="1"
                title="video"
              />
            </div>
          </div>
        </div>
      </div>

      <Mysnackbar
        open={isSnackbarOpen}
        message={error_msg}
        onClose={closeSnackbar}
        // error_msg={error_msg}
        severity={severity}
      />
      <Footer />
    </>
  );
}
