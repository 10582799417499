import React, { useState, useEffect } from "react";
import { Markup } from "interweave";
import service from "../Service/service";
import * as $ from "jquery";
import { RotatingLines } from "react-loader-spinner";
import Header from "../component/Header";
import Footer from "../component/Homepage/footer";

export default function AboutProjects() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");

  const project_detail_by_id = async () => {
    service
      .fetch_project_by_id(localStorage.getItem("P_Id"))
      .then((res) => {
        setTitle(res.data.data[0].title);
        setDescription(res.data.data[0].description);
        setImage(res.data.data[0].image);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    project_detail_by_id();
  }, []);

  if (title !== "") {
    $(".myElem").hide();
    $(".DNone").hide();
  } else {
    $(".myElem").hide();
    $(".DNone").removeClass();
  }

  return (
    <>
      {/* <Header /> */}
      <div class="rs-breadcrumbs img1">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title">About Project</h1>
          <ul>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a class="active" href="/">
                Home
              </a>
            </li>
            <li>About Project</li>
          </ul>
        </div>
      </div>
      <div className="rs-case-studies-single pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <div className="myElem">
            <RotatingLines width="100" strokeColor="#03228f" />
          </div>
          <h2 className="DNone text-center">
            Currently, No project description available
          </h2>
          <div className="row">
            <div className="col md-mb-50">
              <div className="services-img">
                <img
                  src={`https://pcoder.s3.ap-south-1.amazonaws.com/` + image}
                  alt="img"
                />
              </div>
              <h2 className="mt-34">
                <Markup content={title} />
              </h2>
              <p>
                <Markup content={description} />
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
