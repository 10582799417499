/* eslint-disable react-hooks/exhaustive-deps */
import { Markup } from "interweave";
import React, { useState, useEffect } from "react";
import service from "../Service/service";
import * as $ from "jquery";
import { RotatingLines } from "react-loader-spinner";
import Header from "../component/Header";
import Footer from "../component/Homepage/footer";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const AboutBlogs = ({ props }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const params = useParams();

  const fetch_blog_byid = () => {
    service.fetch_blog_by_id(params.id).then((res) => {
      if (res.data.code === 200) {
        setTitle(res.data.data[0].title);
        setDescription(res.data.data[0].description);
        setImage(res.data.data[0].image);
        document.title = res.data.data[0].title ?? "Pcoder Technologies";
      }
    });
  };

  useEffect(() => {
    fetch_blog_byid();
  }, []);

  if (title !== "") {
    $(".myElem").hide();
    $(".DNone").hide();
  } else {
    $(".myElem").hide();
    $(".DNone").removeClass();
  }
  return (
    <>
      <Helmet>
        <meta name="description" content={title} />
      </Helmet>
      {/* <Header /> */}

      <div class="rs-breadcrumbs img2">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title">About Blog</h1>
          <ul>
            <li>
              <a class="active" href="/">
                Home
              </a>
            </li>
            <li>About Blog</li>
          </ul>
        </div>
      </div>

      <div className="rs-inner-blog pt-120 pb-120 md-pt-90 md-pb-90">
        <div className="container">
          <div className="myElem">
            <RotatingLines width="100" strokeColor="#03228f" />
          </div>
          <h2 className="DNone text-center">
            Currently, No blog description available
          </h2>
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-details">
                <div className="blog-full">
                  <h2 style={{}}>
                    <i>
                      <Markup content={title} />
                    </i>
                  </h2>
                  <div className="bs-img mb-35">
                    <img
                      style={{ width: "100%" }}
                      src={
                        `https://pcoder.s3.ap-south-1.amazonaws.com/` + image
                      }
                      alt={
                        title +
                        ` Pcoder Technologies Best and truested Software development company`
                      }
                    />
                  </div>
                  <div>
                    <Markup allowElements content={description} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutBlogs;
