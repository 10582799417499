import React, { useEffect, useState } from "react";
import BrandBanner from "../component/Brand_banner";
import Header from "../component/Header";
import Footer from "../component/Homepage/footer";
import service from "../Service/service";
import { Markup } from "interweave";
import * as $ from "jquery";
import { RotatingLines } from "react-loader-spinner";

export default function Potfolio() {
  const [allProjects, setAllProjects] = useState([]);

  const fetchAll = () => {
    service.fetch_all_project().then((res) => {
      if (res.data.code === 200) {
        setAllProjects(res.data.data);
      }
    });
  };

  useEffect(() => {
    fetchAll();
  }, []);

  if (allProjects?.length > 0) {
    $(".myElem").hide();
    $(".DNone").hide();
  } else {
    $(".myElem").hide();
    $(".DNone").removeClass();
  }

  return (
    <>
      {/* <Header /> */}
      <div class="rs-breadcrumbs img3">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title">Our Work</h1>
          <ul>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a class="active" href="/">
                Home
              </a>
            </li>
            <li>Our Work</li>
          </ul>
        </div>
      </div>
      <div class="rs-project style9 pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="myElem">
          <RotatingLines width="100" strokeColor="#03228f" />
        </div>
        <h2 className="DNone text-center">Currently, No project available</h2>
        <div class="container">
          <div class="row">
            {allProjects &&
              allProjects?.map((data, index) => {
                return (
                  <div class="col-lg-4 col-md-6 mb-30">
                    <div class="project-item">
                      <div class="project-img">
                        <img
                          src={
                            // `https://pcoder.s3.ap-south-1.amazonaws.com/` +
                            data.image
                          }
                          alt="images"
                        />
                      </div>
                      <div class="project-content">
                        <div class="project-inner">
                          <h3 class="title">
                            <a
                              // href="/about_project"
                              // onClick={() => {
                              //   localStorage.setItem("P_Id", data.id);
                              // }}
                            >
                              <a>
                                <Markup content={data.title} />
                              </a>
                            </a>
                          </h3>
                          <span class="category">
                            <a>
                              <Markup content={data.description} />
                            </a>
                          </span>
                          <div class="p-icon">
                            <a href={`${data.link}`}>
                              <i class="fa fa-angle-right"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <BrandBanner />
      <Footer />
    </>
  );
}
