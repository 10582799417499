import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
// import { useEffect } from "react/cjs/react.production.min";

export default function Hero() {
  // const history = useHistory();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      background: "coral",
      overflow: "auto",
      clear: "both",
      zIndex: "10000000000000",
    },
  };

  const autoPlayYouTubeModal = () => {
    var trigger = $("body").find('[data-toggle="modal"]');
    trigger.on("click", function () {
      var theModal = $(this).data("target"),
        videoSRC = $(this).attr("data-theVideo"),
        videoSRCauto = videoSRC + "?autoplay=1";
      $(theModal + " iframe").attr("src", videoSRCauto);
      $(theModal + " button.close").on("click", function () {
        $(theModal + " iframe").attr("src", videoSRC);
      });
    });
  };

  useEffect(() => {
    autoPlayYouTubeModal();
  });

  const stopVideoPlay = async () => {
    $(function () {
      $(".youtube-video")[0].contentWindow.postMessage(
        '{"event":"command","func":"' + "stopVideo" + '","args":""}',
        "*"
      );
    });
  };

  return (
    <>
      <div className="rs-banner style3 rs-rain-animate modify1">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="banner-content">
                <div className="rs-videos">
                  <div className="animate-border white-color style3">
                    <a
                      class="btn btn-default popup-border popup-videos p-0"
                      data-toggle="modal"
                      data-target="#videoModal"
                      data-theVideo="https://www.youtube.com/embed/oH0xl7WxVIA"
                    >
                      <i className="fa fa-play"></i>
                    </a>
                  </div>
                </div>
                <h1 className="title">Lets Build a Tech friendly Future.</h1>
                <p className="desc">
                  A Global Company Offering Professional, Reliable, and
                  Efficient IT Services
                </p>
                <ul className="banner-btn">
                  <li>
                    <a className="readon started text-white" href="/about">
                      Explore More
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="images-part hidden-md">
          <img src="assets/images/banner/banner-4.png" alt="" />
        </div>
        <div className="line-inner style2">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>

      {/* Play Button Video Modal */}

      <div
        id="videoModal"
        class="modal fade bd-example-modal-lg"
        tabindex="-1"
        role="dialog"
        aria-labelledby="videoModal"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={() => stopVideoPlay()}
              >
                ×
              </button>
            </div>
            <div class="modal-body center">
              <iframe
                className="youtube-video"
                style={{ width: "100%", height: "400px" }}
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen="1"
                title="video"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
