import React, { useState } from "react";
import service from "../../Service/service";
import countryCodesList from "country-codes-list";
import Mysnackbar from "../Mysnackbar";

export default function Contact() {
  const [name, setName] = useState("");
  const [email, setemail] = useState("");
  const [mobile_no, setMobile_no] = useState("");
  const [country_code, setCountry_code] = useState("");
  const [website, setWebsite] = useState("");
  const [formErrors, setFormErrors] = useState([]);

  const [error_msg, seterror_msg] = useState("");
  const [severity, setSeverity] = useState("");

  const [isSnackbarOpen, setSnackbarOpen] = useState(false);

  const openSnackbar = () => {
    setSnackbarOpen(true);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleValidation = () => {
    let formErrors = {};
    let formIsValid = true;
    if (!name) {
      formIsValid = false;
      formErrors["name_error"] = "Name is required.";
    }
    if (!email && !mobile_no) {
      if (!email) {
        formIsValid = false;
        formErrors["email_error"] = "Email is required.";
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        formIsValid = false;
        formErrors["email_error"] = "Invalid Email address.";
      }
      if (!mobile_no) {
        formIsValid = false;
        formErrors["mobile_no_error"] = "Mobile No. is required.";
      }
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  const resetFields = () => {
    setName("");
    setemail("");
    setMobile_no("");
    setFormErrors([]);
  };

  const add_Appointment = () => {
    let data = {
      name: name,
      email: email,
      mobile: country_code + mobile_no,
      website: website,
    };
    service
      .appointment(data)
      .then((res) => {
        if (res.data.code === 200) {
          resetFields();
          // alert("Appointment Scheduled Successfully");
          seterror_msg("Appointment Scheduled Successfully.");
          setSeverity("success");
          openSnackbar();
        } else {
          // alert("something went wrong");
          seterror_msg("Appointment Scheduled Successfully.");
          setSeverity("error");
          openSnackbar();
        }
      })
      .catch((e) => {
        // alert(e);
        seterror_msg("Appointment Scheduled Successfully.");
        setSeverity("error");
        openSnackbar();
      });
  };

  const myCountryArray = countryCodesList.all();

  return (
    <div class="rs-why-choose style2 gray-color rs-rain-animate pt-120 pb-120 md-pt-70 md-pb-80">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-7 md-mb-30">
            <div class="sec-title mb-40">
              <div class="sub-text style4-bg left">Let's Talk</div>
              <h2 class="title pb-20">Speak With Expert Engineers.</h2>
              <div class="desc">
                Just click to connect and we do the rest.
                <br />
                get a discussion and proposal in 24Hr.
              </div>
            </div>
            <div class="services-wrap mb-25">
              <div class="services-icon">
                <img src="assets/images/choose/icons/style2/1.png" alt="" />
              </div>
              <div class="services-text">
                <h3 class="title">
                  <a href="#">Email</a>
                </h3>
                <p class="services-txt">info@pcodertechnologies.com</p>
              </div>
            </div>
            <div class="services-wrap mb-25">
              <div class="services-icon">
                <img src="assets/images/choose/icons/style2/2.png" alt="" />
              </div>
              <div class="services-text">
                <h3 class="title">
                  <a href="#">Whatapp Us</a>
                </h3>
                <p class="services-txt"> (+91) 9691-465201</p>
              </div>
            </div>
            <div class="services-wrap">
              <div class="services-icon">
                <img src="assets/images/choose/icons/style2/3.png" alt="" />
              </div>
              <div class="services-text">
                <h3 class="title">
                  <a href="#">Office Address</a>
                </h3>
                <p class="services-txt">
                  S-2 Kripal nagar
                  <br /> 490023, CG, India
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-5">
            <div class="rs-contact mod1">
              <div class="contact-wrap">
                <div class="content-part mb-25">
                  <h2 class="title mb-15">Schedule Appointment</h2>
                  <p class="desc">We here to help you 24/7 with experts</p>
                </div>
                <div id="form-messages"></div>
                <div
                  id="contact-form"
                  // method="post"
                  // action="https://rstheme.com/products/html/braintech/mailer.php"
                >
                  <fieldset>
                    <div class="row">
                      <div class="col-lg-12 mb-15 col-md-6 col-sm-6">
                        <input
                          class="from-control"
                          type="text"
                          id="name"
                          placeholder="Name"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                      </div>
                      {formErrors.name_error && (
                        <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: ".8rem",
                              marginTop: "0px",
                              marginLeft: "25px",
                            }}
                          >
                            {formErrors.name_error}
                          </p>
                        </div>
                      )}
                      <div class="col-lg-12 mb-15 col-md-6 col-sm-6">
                        <input
                          class="from-control"
                          type="text"
                          id="email"
                          placeholder="E-Mail"
                          value={email}
                          onChange={(e) => {
                            setemail(e.target.value);
                          }}
                        />
                        {formErrors.email_error && (
                          <div className="offset-0">
                            <p
                              style={{
                                color: "red",
                                fontSize: ".8rem",
                                marginTop: "0px",
                              }}
                            >
                              {formErrors.email_error}
                            </p>
                          </div>
                        )}
                      </div>

                      <div class="col-lg-12 mb-15 col-md-6 col-sm-6">
                        <div class="input-group" style={{ flexWrap: "nowrap" }}>
                          <select
                            style={{ width: "60%", padding: 0, margin: 0 }}
                            class="form-select from-control"
                            onChange={(e) => setCountry_code(e.target.value)}
                          >
                            {myCountryArray?.map((data, i) => {
                              return (
                                <option
                                  key={i}
                                  selected={
                                    data.countryCallingCode == 91 ? true : false
                                  }
                                  value={`+${data.countryCallingCode}`}
                                >{`+${data.countryCallingCode}`}</option>
                              );
                            })}
                          </select>
                          <input
                            class="from-control"
                            type="tel"
                            id="phone"
                            name="phone"
                            value={mobile_no}
                            placeholder="Phone Number"
                            required=""
                            onChange={(e) => {
                              setMobile_no(e.target.value);
                            }}
                          />
                        </div>
                        {formErrors.mobile_no_error && (
                          <div className="offset-0">
                            <p
                              style={{
                                color: "red",
                                fontSize: ".8rem",
                                marginTop: "0px",
                              }}
                            >
                              {formErrors.mobile_no_error}
                            </p>
                          </div>
                        )}
                      </div>
                      <div class="col-lg-12 mb-25 col-md-6 col-sm-6">
                        <input
                          class="from-control"
                          type="text"
                          id="Website"
                          placeholder="Your Website"
                          value={website}
                          onChange={(e) => {
                            setWebsite(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div class="form-group mb-0">
                      <input
                        onClick={() => {
                          if (handleValidation()) {
                            add_Appointment();
                          } else {
                            // alert("Please complete all data");
                            seterror_msg("Please fill all required fields.");
                            setSeverity("info");
                            openSnackbar();
                          }
                        }}
                        class="submit-btn orange-btn"
                        value="Submit Now"
                        type="submit"
                      />
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Mysnackbar
        open={isSnackbarOpen}
        message={error_msg}
        onClose={closeSnackbar}
        // error_msg={error_msg}
        severity={severity}
      />
      <div class="line-inner">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
    </div>
  );
}
