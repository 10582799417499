import React, { useState } from "react";
import service from "../../Service/service";
import { useNavigate } from "react-router-dom";
import Mysnackbar from "../Mysnackbar";

export default function Footer() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [formErrors, setFormErrors] = useState([]);

  const [error_msg, seterror_msg] = useState("");
  const [severity, setSeverity] = useState("");

  const [isSnackbarOpen, setSnackbarOpen] = useState(false);

  const openSnackbar = () => {
    setSnackbarOpen(true);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (!email) {
      formIsValid = false;
      formErrors["email_error"] = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formIsValid = false;
      formErrors["email_error"] = "Invalid Email address.";
    }
    setFormErrors(formErrors);
    return formIsValid;
  };

  const Add_Newsletter = () => {
    service
      .add_email({ email: email })
      .then((res) => {
        if (res.data.code === 200) {
          setEmail("");
          // alert("Subscribed Successfully");
          seterror_msg("Subscribed Sucessfully");
          setSeverity("success");
          openSnackbar();
          // window.location.reload();
        }
      })
      .catch((e) => {
        // alert(e);
        seterror_msg("Something went wrong.");
        setSeverity("error");
        openSnackbar();
      });
  };

  return (
    <>
      <footer id="rs-footer" class="rs-footer">
        <div class="footer-top">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-12 col-sm-12 footer-widget">
                <div class="footer-logo mb-30">
                  <a href="/">
                    <img src="assets/images/logo-dark.png" alt="" />
                  </a>
                </div>
                <div class="textwidget pb-30">
                  <p>
                    We develop idea, Business, Startups and Dreams and Happy
                    Customer.
                  </p>
                </div>
                <ul class="footer-social md-mb-30">
                  {/* <li> 
                                  <a target="_blank"><span><i class="fa fa-facebook"></i></span></a> 
                              </li>
                              <li> 
                                  <a target="_blank"><span><i class="fa fa-twitter"></i></span></a> 
                              </li>

                              <li> 
                                  <a target="_blank"><span><i class="fa fa-pinterest-p"></i></span></a> 
                              </li>
                              <li> 
                                  <a target="_blank"><span><i class="fa fa-instagram"></i></span></a> 
                              </li> */}
                </ul>
              </div>
              <div class="col-lg-3 col-md-12 col-sm-12 pl-45 md-pl-15 md-mb-30">
                <h3 class="widget-title">Quick Menu</h3>
                <ul class="site-map">
                  <li
                    onClick={() => {
                      navigate("/about");
                    }}
                  >
                    <a style={{ cursor: "pointer" }}>About</a>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/service");
                    }}
                  >
                    <a style={{ cursor: "pointer" }}>Services</a>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/ourwork");
                    }}
                  >
                    <a style={{ cursor: "pointer" }}>Our Work</a>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/contact");
                    }}
                  >
                    <a style={{ cursor: "pointer" }}>Get In Touch</a>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/careers");
                    }}
                  >
                    <a style={{ cursor: "pointer" }}>Careers</a>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/blog");
                    }}
                  >
                    <a style={{ cursor: "pointer" }}>Blog</a>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/events");
                    }}
                  >
                    <a style={{ cursor: "pointer" }}>Events</a>
                  </li>
                </ul>
              </div>
              <div class="col-lg-3 col-md-12 col-sm-12 md-mb-30">
                <h3 class="widget-title">Contact Info</h3>
                <ul class="address-widget">
                  <li>
                    <i class="flaticon-location"></i>
                    <div class="desc">Durg, India</div>
                  </li>
                  <li>
                    <i class="flaticon-call"></i>
                    <div class="desc">
                      <a href="tel:(+91)9691465201">(+91) 9691-465201</a>
                    </div>
                  </li>
                  <li>
                    <i class="flaticon-email"></i>
                    <div class="desc">
                      <a href="mailto:info@pcodertechnologies.com">
                        info@pcodertechnologies.com
                      </a>
                    </div>
                  </li>
                  <li>
                    <i class="flaticon-clock-1"></i>
                    <div class="desc">Opening Hours: 24Hr X 7Days</div>
                  </li>
                </ul>
              </div>
              <div class="col-lg-3 col-md-12 col-sm-12">
                <h3 class="widget-title">Newsletter</h3>
                <p class="widget-desc">
                  We release Marketing updates and Business opportunity and Jobs
                  update with News Letter.
                </p>
                <p>
                  <input
                    type="email"
                    name="EMAIL"
                    placeholder="Your email address"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  {formErrors.email_error && (
                    <div>
                      <p
                        style={{
                          color: "red",
                          fontSize: ".8rem",
                          marginTop: "-20px",
                          marginLeft: "20px",
                        }}
                      >
                        {formErrors.email_error}
                      </p>
                    </div>
                  )}
                  <a
                    onClick={() => {
                      if (handleValidation()) {
                        Add_Newsletter();
                      } else {
                        // alert("Please complete data");
                        seterror_msg("Please fill all the required fields.");
                        setSeverity("error");
                        openSnackbar();
                      }
                    }}
                    class="paper-plane"
                  >
                    <input type="submit" value="Sign up" />
                  </a>
                  <i class="flaticon-send"></i>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bottom">
          <div class="container">
            <div class="row y-middle">
              {/* <div class="col-lg-6 text-right md-mb-10 order-last">
                <ul class="copy-right-menu">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/about">About</a>
                  </li>
                  <li>
                    <a href="/blog">Blog</a>
                  </li>
                  <li>
                    <a>Shop</a>
                  </li>
                  <li>
                    <a>FAQs</a>
                  </li>
                </ul>
              </div> */}
              <div class="col-lg-6">
                <div class="copyright">
                  <p>
                    &copy; {new Date().getFullYear()} All Rights Reserved.
                    Developed By{" "}
                    <a href="https://pcodertechnologies.com/">
                      P Coders Tecnology
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <Mysnackbar
        open={isSnackbarOpen}
        message={error_msg}
        onClose={closeSnackbar}
        severity={severity}
      />
      <div id="scrollUp" class="orange-color">
        <i class="fa fa-angle-up"></i>
      </div>
    </>
  );
}
