import React, { useState } from "react";
import Header from "../component/Header";
import Footer from "../component/Homepage/footer";
import service from "../Service/service";
import countryCodesList from "country-codes-list";
import swal from "sweetalert";

export default function ContactUS() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_no, setPhone_no] = useState("");
  const [country_code, setCountry_code] = useState("");
  const [website, setWebsite] = useState("");
  const [your_message, setYour_message] = useState("");
  const [formErrors, setFormErrors] = useState([]);

  const handleValidation = () => {
    let formErrors = {};
    let formIsValid = true;
    if (!name) {
      formIsValid = false;
      formErrors["name_error"] = "Name is required.";
    }
    if (!email && !phone_no) {
      if (!email) {
        formIsValid = false;
        formErrors["email_error"] = "Email is required.";
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        formIsValid = false;
        formErrors["email_error"] = "Invalid Email Address.";
      }
      if (!phone_no) {
        formIsValid = false;
        formErrors["phone_no_error"] = "Phone No. is required.";
      }
    }
    if (!your_message) {
      formIsValid = false;
      formErrors["your_message_error"] = "Your Message is required.";
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  const Get_In_Touch = () => {
    let data = {
      name: name,
      email: email,
      mobile: country_code + phone_no,
      website: website,
      message: your_message,
    };
    service
      .contect_us(data)
      .then((res) => {
        if (res.data.code === 200) {
          swal("Thanks for you message we will contact us shortly.");
          window.location.reload();
        } else {
          swal(
            "Oops looks something wrong please contact to info@pcodertechnologies.com"
          );
        }
      })
      .catch((e) => {
        swal(e);
      });
  };

  const myCountryArray = countryCodesList.all();

  return (
    <>
      {/* <Header /> */}
      <div class="rs-breadcrumbs img4">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title">Contact</h1>
          <ul>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a class="active" href="/">
                Home
              </a>
            </li>
            <li>Contact</li>
          </ul>
        </div>
      </div>
      <div class="rs-contact pt-120 md-pt-80">
        <div class="container">
          <div class="row">
            <div class="col-lg-5 md-mb-60">
              <div class="contact-box">
                <div class="sec-title mb-45">
                  <span class="sub-text new-text white-color">Let's Talk</span>
                  <h2 class="title white-color">
                    Speak With Expert Engineers.
                  </h2>
                </div>
                <div class="address-box mb-25">
                  <div class="address-icon">
                    <i class="fa fa-home"></i>
                  </div>
                  <div class="address-text">
                    <span class="label">Email:</span>
                    <a href="!#">info@pcodertechnologies.com</a>
                  </div>
                </div>
                <div class="address-box mb-25">
                  <div class="address-icon">
                    <i class="fa fa-phone"></i>
                  </div>
                  <div class="address-text">
                    <span class="label">Phone:</span>
                    <a href="tel:9691465201">(+91) 9691-465201</a>
                  </div>
                </div>
                <div class="address-box">
                  <div class="address-icon">
                    <i class="fa fa-map-marker"></i>
                  </div>
                  <div class="address-text">
                    <span class="label">Address:</span>
                    <div class="desc">1423/S2 Kripal Nagar Sadbhwana chowk</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-7 pl-70 md-pl-15">
              <div class="contact-widget">
                <div class="sec-title2 mb-40">
                  <span class="sub-text contact mb-15">Get In Touch</span>
                  <h2 class="title testi-title">Fill The Form Below</h2>
                </div>
                <div id="form-messages"></div>
                <form id="contact-form">
                  <fieldset>
                    <div class="row">
                      <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                        <input
                          class="from-control"
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Name"
                          required=""
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                        {formErrors.name_error && (
                          <div className="offset-0">
                            <p
                              style={{
                                color: "red",
                                fontSize: ".8rem",
                                marginTop: "0px",
                              }}
                            >
                              {formErrors.name_error}
                            </p>
                          </div>
                        )}
                      </div>
                      <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                        <input
                          class="from-control"
                          type="text"
                          id="email"
                          name="email"
                          placeholder="E-Mail"
                          required=""
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                        {formErrors.email_error && (
                          <div className="offset-0">
                            <p
                              style={{
                                color: "red",
                                fontSize: ".8rem",
                                marginTop: "0px",
                              }}
                            >
                              {formErrors.email_error}
                            </p>
                          </div>
                        )}
                      </div>
                      <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                        <div class="input-group" style={{ flexWrap: "nowrap" }}>
                          <select
                            style={{ width: "60%", padding: 0, margin: 0 }}
                            class="form-select from-control"
                            onChange={(e) => setCountry_code(e.target.value)}
                          >
                            {myCountryArray?.map((data, i) => {
                              return (
                                <option
                                  key={i}
                                  selected={
                                    parseInt(data.countryCallingCode) === 91
                                      ? true
                                      : false
                                  }
                                  value={`+${data.countryCallingCode}`}
                                >{`+${data.countryCallingCode}`}</option>
                              );
                            })}
                          </select>
                          <input
                            class="from-control"
                            type="tel"
                            id="phone"
                            name="phone"
                            placeholder="Phone Number"
                            required=""
                            onChange={(e) => {
                              setPhone_no(e.target.value);
                            }}
                          />
                        </div>
                        {formErrors.phone_no_error && (
                          <div className="offset-0">
                            <p
                              style={{
                                color: "red",
                                fontSize: ".8rem",
                                marginTop: "0px",
                              }}
                            >
                              {formErrors.phone_no_error}
                            </p>
                          </div>
                        )}
                      </div>
                      <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                        <input
                          class="from-control"
                          type="text"
                          id="Website"
                          name="subject"
                          placeholder="Your Website"
                          required=""
                          onChange={(e) => {
                            setWebsite(e.target.value);
                          }}
                        />
                      </div>

                      <div class="col-lg-12 mb-30">
                        <textarea
                          class="from-control"
                          id="message"
                          name="message"
                          placeholder="Your message Here"
                          required=""
                          onChange={(e) => {
                            setYour_message(e.target.value);
                          }}
                        ></textarea>
                        {formErrors.your_message_error && (
                          <div className="offset-0">
                            <p
                              style={{
                                color: "red",
                                fontSize: ".8rem",
                                marginTop: "0px",
                              }}
                            >
                              {formErrors.your_message_error}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div class="btn-part">
                      <div class="form-group mb-0">
                        <input
                          class="readon learn-more submit text-center"
                          value="Submit Now"
                          type="button"
                          onClick={() => {
                            if (handleValidation()) {
                              Get_In_Touch();
                            } else {
                              swal("Please fill all required fields.");
                            }
                          }}
                        />
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="map-canvas pt-120 md-pt-80">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.323796385843!2d81.34450931488675!3d21.219004985895626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a2923144486c5eb%3A0xacad20f5cc5af046!2spcodertechnologies!5e0!3m2!1sen!2sin!4v1642106890867!5m2!1sen!2sin"
            width="800"
            height="600"
            allowfullscreen=""
            loading="lazy"
            title="Pcoder Video"
          ></iframe>
        </div>
      </div>
      <Footer />
    </>
  );
}
