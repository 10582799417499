import React from "react";
import * as $ from "jquery";
import { useHistory, useLocation, useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

export default function Header() {
  // const history = useHistory();
  const navigate = useNavigate();
  const close_nav = (e) => {
    console.log("helo ok");
    e.preventDefault();
    var menu_height = $(".rs-menu ul").height();

    if ($(".rs-menu-toggle").hasClass("rs-menu-toggle-open")) {
      $(".rs-menu-toggle")
        .removeClass("rs-menu-toggle-open")
        .addClass("rs-menu-toggle-close");
      $(".rs-menu")
        .animate({ height: "0px" }, { queue: false, duration: 300 })
        .addClass("rs-menu-close");
    } else {
      $(".rs-menu-toggle")
        .removeClass("rs-menu-toggle-close")
        .addClass("rs-menu-toggle-open");
      $(".rs-menu")
        .animate({ height: menu_height }, { queue: false, duration: 300 })
        .removeClass("rs-menu-close");
    }
  };

  const openNav = (e) => {
    e.preventDefault();
    if ($(window).width() >= 1000) $("body").toggleClass("nav-expanded");
  };
  const openMobileNav = (e) => {
    e.preventDefault();
    if ($(window).width() >= 500) $(".rs-menu").toggleClass("nav-close");
  };

  React.useEffect(() => {
    call_head();
  }, []);
  const call_head = () => {
    window.onscroll = () => {
      if (window.pageYOffset < 1) {
        $(".menu-sticky").removeClass("sticky");
      } else {
        $(".menu-sticky").addClass("sticky");
      }
    };
  };

  const location = useLocation();

  return (
    <div className="full-width-header">
      <header
        id="rs-header"
        className="rs-header style3 modify2 header-transparent"
      >
        <div className="menu-area menu-sticky">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-2">
                <div className="logo-part">
                  <a
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    <img
                      className="normal-logo"
                      src="/assets/images/logo-light.png"
                      alt="logo"
                    />
                    <img
                      className="sticky-logo"
                      src="/assets/images/logo-dark.png"
                      alt="logo"
                    />
                  </a>
                </div>
                <div className="mobile-menu">
                  <a className="rs-menu-toggle rs-menu-toggle-close">
                    <i
                      onClick={(e) => {
                        openMobileNav(e);
                      }}
                      className="fa fa-bars"
                    ></i>
                  </a>
                </div>
              </div>
              <div
                className="col-lg-10 text-center"
                style={{ paddingRight: 0, paddingLeft: 0 }}
              >
                <div className="rs-menu-area">
                  <div className="main-menu">
                    <nav className="rs-menu mr-25 ">
                      <ul className="nav-menu overflow-auto">
                        <li
                          hidden={location.pathname === "/" ? true : false}
                          className="menu-item-has-children navbar-link navlink"
                          // className={
                          //   location.pathname === "/"
                          //     ? "menu-item-has-children current-menu-item"
                          //     : "menu-item-has-children"
                          // }
                          onClick={(e) => {
                            navigate("/");
                            close_nav(e);
                          }}
                        >
                          <a style={{ cursor: "pointer" }}>Home</a>
                        </li>
                        <li
                          hidden={location.pathname === "/about" ? true : false}
                          className="menu-item-has-children"
                          // className={
                          //   location.pathname === "/about"
                          //     ? "menu-item-has-children current-menu-item"
                          //     : "menu-item-has-children"
                          // }
                          onClick={(e) => {
                            navigate("/about");
                            close_nav(e);

                          }}
                        >
                          <a style={{ cursor: "pointer" }}>About</a>
                        </li>
                        {/* <li
                          className={
                            location.pathname === "/blog"
                              ? "menu-item-has-children current-menu-item"
                              : "menu-item-has-children"
                          }
                        >
                          <a href="/blog" style={{ cursor: "pointer" }}>
                            Blog
                          </a>
                        </li> */}
                        <li
                          hidden={
                            location.pathname === "/service" ? true : false
                          }
                          className="menu-item-has-children"
                          // className={
                          //   location.pathname === "/service"
                          //     ? "menu-item-has-children current-menu-item"
                          //     : "menu-item-has-children"
                          // }
                          onClick={(e) => {
                            navigate("/service");
                            close_nav(e);

                          }}
                        >
                          <a style={{ cursor: "pointer" }}>Services</a>
                        </li>
                        <li
                          hidden={
                            location.pathname === "/ourwork" ? true : false
                          }
                          className="menu-item-has-children"
                          // className={
                          //   location.pathname === "/ourwork"
                          //     ? "menu-item-has-children current-menu-item"
                          //     : "menu-item-has-children"
                          // }
                          onClick={(e) => {
                            navigate("/ourwork");
                            close_nav(e);

                          }}
                        >
                          <a style={{ cursor: "pointer" }}>Our Work</a>
                        </li>
                        <li
                          hidden={
                            location.pathname === "/contact" ? true : false
                          }
                          className="menu-item-has-children"
                          // className={
                          //   location.pathname === "/contact"
                          //     ? "menu-item-has-children current-menu-item"
                          //     : "menu-item-has-children"
                          // }
                          onClick={(e) => {
                            navigate("/contact");
                            close_nav(e);

                          }}
                        >
                          <a style={{ cursor: "pointer" }}>Get In Touch</a>
                        </li>
                        {/* <li
                          hidden={
                            location.pathname === "/careers" ? true : false
                          }
                          className="menu-item-has-children"
                          onClick={() => {
                            navigate("/careers");
                          }}
                        >
                          <a style={{ cursor: "pointer" }}>Careers</a>
                        </li> */}
                        {/* <li
                          className={
                            location.pathname === "/events"
                              ? "menu-item-has-children current-menu-item"
                              : "menu-item-has-children"
                          }
                        >
                          <a href="/events" style={{ cursor: "pointer" }}>
                            Events
                          </a>
                        </li> */}
                      </ul>
                    </nav>
                  </div>
                  <div className="expand-btn-inner search-icon hidden-md">
                    <ul>
                      <li>
                        <a
                          id="nav-expander"
                          className="humburger nav-expander"
                          onClick={(e) => {
                            openNav(e);
                          }}
                        >
                          <span className="dot1"></span>
                          <span className="dot2"></span>
                          <span className="dot3"></span>
                          <span className="dot4"></span>
                          <span className="dot5"></span>
                          <span className="dot6"></span>
                          <span className="dot7"></span>
                          <span className="dot8"></span>
                          <span className="dot9"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <nav className="right_menu_togle hidden-md">
        <div className="close-btn">
          <div className="nav-link">
            <a
              id="nav-close"
              className="humburger nav-expander"
              onClick={(e) => {
                openNav(e);
              }}
            >
              <span className="dot1"></span>
              <span className="dot2"></span>
              <span className="dot3"></span>
              <span className="dot4"></span>
              <span className="dot5"></span>
              <span className="dot6"></span>
              <span className="dot7"></span>
              <span className="dot8"></span>
              <span className="dot9"></span>
            </a>
          </div>
        </div>
        <div
          className="canvas-logo"
          onClick={() => {
            navigate("/");
          }}
        >
          <a>
            <img src="assets/images/logo-dark.png" alt="logo" />
          </a>
        </div>
        <div className="offcanvas-text">
          <p>
            <b>Pcoder Technologies you Growing Partner</b>
          </p>
        </div>
        <div className="canvas-contact">
          <div className="address-area">
            <div className="address-list">
              <div className="info-icon">
                <i className="flaticon-location"></i>
              </div>
              <div className="info-content">
                <h4 className="title">Address</h4>
                <em>1423 S-2 Kripal Nagar Bhilai</em>
              </div>
            </div>
            <div className="address-list">
              <div className="info-icon">
                <i className="flaticon-email"></i>
              </div>
              <div className="info-content">
                <h4 className="title">Email</h4>
                <em>
                  <a href="mailto:info@pcodertechnologies.com">
                    info@pcodertechnologies.com
                  </a>
                </em>
              </div>
            </div>
            <div className="address-list">
              <div className="info-icon">
                <i className="flaticon-call"></i>
              </div>
              <div className="info-content">
                <h4 className="title">Phone</h4>
                <em>(+91) 9691-465201</em>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
