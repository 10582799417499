import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import Header from "../component/Header";
import Footer from "../component/Homepage/footer";
import service from "../Service/service";
import * as $ from "jquery";
import { RotatingLines } from "react-loader-spinner";

const CareerSpecs = () => {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState("");
  const [resume, setResume] = useState("");
  const [cvresume, setCVResume] = useState("");

  const [name, setName] = useState("");

  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [mobileNoOp, setMobileNoOp] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [github, setGithub] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [otherLinks, setOtherLinks] = useState("");

  const [jobData, setJobData] = useState("");
  const [jobId, setJobId] = useState("");
  const [type, setType] = useState("");
  const [location, setLocation] = useState("");
  const [salary, setSalary] = useState("");
  const [description, setDescription] = useState("");
  const [responsibilities, setResponsibilities] = useState("");
  const [requirements, setRequirements] = useState("");

  const resetFields = () => {
    setResume("");
    setName("");

    setEmail("");
    setMobileNo("");
    setMobileNoOp("");
    setCity("");
    setState("");
    setCountry("");
    setAddress("");
    setAdditionalInfo("");
    setGithub("");
    setLinkedIn("");
    setOtherLinks("");
  };

  const [formErrors, setFormErrors] = useState([]);
  const handleValidation = () => {
    let formErrors = {};
    let formIsValid = true;
    if (!resume) {
      formIsValid = false;
      formErrors["resume_error"] = "Resume is required.";
    }

    if (!cvresume) {
      formIsValid = false;
      formErrors["cvresume_error"] = "Cover Letter is required.";
    }
    if (!name) {
      formIsValid = false;
      formErrors["Name_error"] = "Name is required.";
    }

    if (!email) {
      formIsValid = false;
      formErrors["email_error"] = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formIsValid = false;
      formErrors["email_error"] = "Invalid Email address.";
    }

    if (!mobileNo) {
      formIsValid = false;
      formErrors["mobileNo_error"] = "Mobile Number is required.";
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  const job_detail_by_id = async () => {
    service
      .fetch_job(localStorage.getItem("J_Id"))
      .then((res) => {
        setJobData(res.data.brands);
        setJobId(res.data.brands[0].job_id);
        setPosition(res.data.brands[0].position);
        setType(res.data.brands[0].type);
        setLocation(res.data.brands[0].location);
        setSalary(res.data.brands[0].salary);
        setDescription(res.data.brands[0].description);
        setResponsibilities(res.data.brands[0].responsibilities);
        setRequirements(res.data.brands[0].requirements);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    job_detail_by_id();
  }, []);

  const JobApplication = async () => {
    let data = new FormData();
    data.append("resume", resume);
    data.append("cvresume", cvresume);

    data.append("job_id", jobId);

    data.append("name", name);

    data.append("email", email);
    data.append("mobile1", mobileNo);

    service
      .add_application(data)
      .then((res) => {
        if (res.data.code === 200) {
          resetFields();
          swal({
            icon: "success",
            text: `Your Application Submitted Successfully`,
          });
        }
      })
      .catch((err) => {
        swal({
          icon: "error",
          text: `Something went wrong...`,
        });
      });
  };

  const Responsibilities = responsibilities.split(",");
  const Requirements = requirements.split(",");

  if (jobData?.length > 0) {
    $(".myElem").hide();
    $(".DNone").hide();
  } else {
    $(".myElem").hide();
    $(".DNone").removeClass();
  }

  return (
    <>
      {/* <Header /> */}

      <div className="rs-breadcrumbs img2">
        <div className="breadcrumbs-inner text-center">
          <h1 className="page-title">Careers</h1>
          <ul>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a className="active" href="/">
                Home
              </a>
            </li>
            <li>
              <a className="active" href="/careers">
                Careers
              </a>
            </li>
            <li>Career Specs</li>
          </ul>
        </div>
      </div>

      <div className="container">
        <div className="myElem">
          <RotatingLines width="100" strokeColor="#03228f" />
        </div>
        <h2 className="DNone text-center">
          Currently, No job description available
        </h2>
        <div className="m-2">
          {jobData?.length > 0 ? (
            <div>
              <div className="row">
                <div className="col-lg-8 col-sm-12 ">
                  <p
                    className="CareerSpecsH"
                    style={{
                      margin: 0,
                      fontSize: "40px",
                    }}
                  >
                    {position}
                  </p>
                  <div style={{ display: "grid" }}>
                    <span className="mr-5">
                      <i
                        style={{ color: "#03228f" }}
                        className="fa fa-map-marker mr-2"
                      ></i>
                      {location}
                    </span>
                    <span className="mr-5">
                      <i
                        style={{ color: "#03228f" }}
                        className="fa fa-inr mr-2"
                      ></i>
                      {salary}
                    </span>
                    <span className="mr-5">
                      <i
                        style={{ color: "#03228f" }}
                        className="fa fa-calendar mr-2"
                      ></i>
                      {type}
                    </span>
                  </div>
                </div>
                <div className="col-4 mt-3">
                  <div className="btn-part">
                    <div className="form-group">
                      <a href="#ApplyForm">
                        <input
                          className="readon learn-more submit text-center BtnP0"
                          value="Apply Now"
                          type="button"
                          onClick={() => {
                            setVisible(true);
                          }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <p>{description}</p>
              </div>

              <div className="mt-5">
                <p
                  style={{
                    margin: 0,
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Responsibilities:
                </p>
                {Responsibilities?.map((data) => {
                  return (
                    <p>
                      <i
                        className="fa fa-check-circle mr-2"
                        style={{ color: "#03228f" }}
                      ></i>
                      {data}
                    </p>
                  );
                })}
              </div>

              <div className="mt-3">
                <p
                  style={{
                    margin: 0,
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Requirements:
                </p>
                {Requirements?.map((data) => {
                  return (
                    <p>
                      <i
                        className="fa fa-check-circle mr-2"
                        style={{ color: "#4e95ed" }}
                      ></i>
                      {data}
                    </p>
                  );
                })}
              </div>
            </div>
          ) : (
            ""
          )}

          <div
            id="ApplyForm"
            className="mt-5"
            style={{
              border: "2px solid #03228f",
              padding: "20px",
            }}
            hidden={visible === false ? true : false}
          >
            <div align="center">
              <p
                style={{
                  textTransform: "uppercase",
                  paddingTop: "5px",
                  fontSize: "15px",
                  fontWeight: "bold",
                  borderBottom: "2px solid #03228f",
                  color: "#03228f",
                  textAlign: "center",
                  width: "200px",
                  height: "40px",
                }}
              >
                Apply for this job
              </p>
            </div>
            <form>
              <div className="form-group">
                <label for="formGroupExampleInput">
                  Applying For (Position)
                </label>
                <input
                  type="text"
                  id="formGroupExampleInput"
                  className="form-control"
                  placeholder="Enter applying for"
                  disabled
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label for="formGroupExampleInput">
                  Attach Resume (CV)<sup>*</sup>
                </label>
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="customFile"
                    accept=".pdf,.doc,.docx"
                    onChange={(e) => {
                      setResume(e.target.files[0]);
                    }}
                  />
                  <label className="custom-file-label" for="customFile">
                    Choose file
                  </label>
                </div>
                {formErrors.resume_error && (
                  <div className="offset-0">
                    <p
                      style={{
                        color: "red",
                        fontSize: ".8rem",
                        margin: 0,
                      }}
                    >
                      {formErrors.resume_error}
                    </p>
                  </div>
                )}
              </div>

              {/* field for cv */}
              <div className="form-group">
                <label for="formGroupExampleInput">
                  Attach Cover Letter<sup>*</sup>
                </label>
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="customFile"
                    accept=".pdf,.doc,.docx"
                    onChange={(e) => {
                      setCVResume(e.target.files[0]);
                    }}
                  />
                  <label className="custom-file-label" for="customFile">
                    Choose file
                  </label>
                </div>
                {formErrors.resume_error && (
                  <div className="offset-0">
                    <p
                      style={{
                        color: "red",
                        fontSize: ".8rem",
                        margin: 0,
                      }}
                    >
                      {formErrors.cvresume_error}
                    </p>
                  </div>
                )}
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label for="formGroupExampleInput">
                      Name<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      id="formGroupExampleInput"
                      className="form-control"
                      placeholder="Enter Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {formErrors.Name_error && (
                      <div className="offset-0">
                        <p
                          style={{
                            color: "red",
                            fontSize: ".8rem",
                            margin: 0,
                          }}
                        >
                          {formErrors.Name_error}
                        </p>
                      </div>
                    )}
                  </div>

                  {/* email field */}
                  <div className="col-md-6 col-sm-12">
                    <label for="formGroupExampleInput">
                      Email<sup>*</sup>
                    </label>
                    <input
                      type="email"
                      id="formGroupExampleInput"
                      className="form-control"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {formErrors.email_error && (
                      <div className="offset-0">
                        <p
                          style={{
                            color: "red",
                            fontSize: ".8rem",
                            margin: 0,
                          }}
                        >
                          {formErrors.email_error}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <label for="formGroupExampleInput">
                      Mobile Number<sup>*</sup>
                    </label>
                    <input
                      type="number"
                      id="formGroupExampleInput"
                      className="form-control"
                      placeholder="Enter mobile number"
                      value={mobileNo}
                      onChange={(e) => setMobileNo(e.target.value)}
                    />
                    {formErrors.mobileNo_error && (
                      <div className="offset-0">
                        <p
                          style={{
                            color: "red",
                            fontSize: ".8rem",
                            margin: 0,
                          }}
                        >
                          {formErrors.mobileNo_error}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label for="formGroupExampleInput">Additional Info</label>
                <textarea
                  id="formGroupExampleInput"
                  className="form-control"
                  placeholder="Enter additional info"
                  rows="3"
                  value={additionalInfo}
                  onChange={(e) => setAdditionalInfo(e.target.value)}
                ></textarea>
              </div>

              <div align="center">
                <input
                  className="readon learn-more submit text-center mt-2"
                  value="Submit"
                  type="button"
                  onClick={() => {
                    if (handleValidation()) {
                      JobApplication();
                    } else {
                      swal({
                        icon: "info",
                        text: `Please Fill All Required Fields`,
                      });
                    }
                  }}
                />
              </div>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default CareerSpecs;
